.body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: #dde4fa;
  overflow: hidden;
}

.container {
  width: 30vw;
  min-height: 100vh;
  background: #4160cf;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 1024px) {
    width: 30vw;
  }

  @media (max-width: 1180px) {
    width: 50vw;
  }

  @media (max-width: 853px) {
    width: 50vw;
  }

  @media (max-width: 438px) {
    width: 100vw;
  }
}

.card {
  background: white;
  width: 92%;
  height: 100%;
  margin: 0 auto;
  padding: 22px;
  border-radius: 20px;
}

.input {
  width: 100%; /* Ensure input fields take full width */
}

.logo {
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.button {
  background-color: #152766;
  color: white;

  &:disabled,
  &[data-disabled] {
    background-color: #cad9e4;
    color: grey;
  }
}
