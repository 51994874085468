.body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: #152766;
  padding: 10px;
  overflow: hidden;
}

.card {
  max-width: 90%;
  border-radius: 8rem;
}

.logo {
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.button {
  background-color: #152766;
  color: white;

  &:disabled,
  &[data-disabled] {
    background-color: #cad9e4;
    color: grey;
  }
}
