.body {
  background-color: #f7f7f7;
}

.titlePage {
  font-size: 22px !important;
  line-height: 30px !important;
  font-weight: bold !important;

  @media (max-width: 768px) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.button {
  &:hover {
    background-color: #3d4d8a;
    color: rgb(248, 249, 250);
  }
}
